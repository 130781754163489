import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HubService } from './abstract.hub.service';
import { ReplaySubject, Subject } from 'rxjs';
import { Decision } from '../generated/model/decision';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class DecisionNotificationService extends HubService {
  protected override console = LogService.initialize('DecisionNotificationService');
  private _decision$: Subject<Decision>;

  constructor(configService: ConfigService) {
    super();
    this.console.log(`constructor()`);
    const { decisionBaseUrl } = configService.config;
    this._baseUrl = decisionBaseUrl;
    this._decision$ = new ReplaySubject<Decision>(1);
  }

  public get decision$(): Subject<Decision> {
    return this._decision$;
  }

  public async sendDecision(decision: Decision): Promise<boolean> {

    let detail = '';

    if (decision.what?.market) {
      detail += ` ${decision.what?.market}`;
    }

    if (decision.what?.productId) {
      detail += ` ${decision.what?.productId}`;
    }

    if (decision.what?.projectId) {
      detail += ` ${decision.what?.projectId}`;
    }

    if (decision.what?.stringValue) {
      detail += ` ${decision.what?.stringValue}`;
    }

    if (decision.what?.boolValue !== undefined) {
      detail += ` ${decision.what?.boolValue}`;
    }

    if (decision.what?.intValue !== undefined) {
      detail += ` ${decision.what?.intValue}`;
    }
    this.console.log(`sendDecision ${decision.what?.key}${detail}`, decision);

    if (this.hubConnection) {
      // try {
      //   await this.hubConnection.invoke('Message', JSON.stringify(decision));
      // } catch (error) {
      //   console.error(error);
      //   window.alert(error);
      // }

      try {
        await this.hubConnection.invoke('Decision', decision);
        return Promise.resolve(true);
      } catch (error) {
        console.error('Decision:', error);
        window.alert(error); // TODO@NST
      }
    }

    return Promise.resolve(false);
  }
  protected getUrl(teamId: string): string {
    return  `${this.baseUrl}/decisionhub?instanceId=${encodeURIComponent(teamId)}`;
  }

  protected onHubConnectionBuilt(): void {
    if (this.hubConnection) {
      const event: string = 'DecisionReceived';
      this.hubConnection.on(event, message => {
        this.console.log('hubConnection.on', event, message);
        this.decision$.next(message);
      });
    }
  }
}
